import bookSVG from '../icons/icon_chaseLinkBook.svg';
import computerSVG from '../icons/icon_chaseLinkComputer.svg';
import leafSVG from '../icons/icon_chaseLinkLeaf.svg';
import gearSVG from '../icons/icon_chaseLinkGears.svg';
import navigationSVG from '../icons/icon_navgation.svg';
import faqsSVG from '../icons/icon_FAQs.svg';
import peopleGroupSVG from '../icons/icon_people_group.svg';
import { getAEMBackendUrls } from '../utils/Utils';
export const BRAND_NAME = 'Jaguar';
export const onlineProfileIdentifier = "54297629";
export const logLabel = {
    error: "ERROR",
    debug: "DEBUG",
    info: "INFO"
};
export const beforeMyAccCall = "Invoking my account api call";
export const afterMyAccCall = "Successfully retrieved account's data";
export const logErrCode = {
    reactErr: "React_Exception",
    aemErr: "AEM_Exception"
};
export const logErrDetails = {
    reactErr: "UI exception occured : ",
    aemErr: "Exception occurred while connecting to AEM : ",
    myAcc: "Exception occurred while connecting to My Account Experience API : ",
    logger: "Exception occurred while connecting to Logger API : ",
};
export const API_URLS = {
    MyAccount: "/v1/myaccount",
    Logger: "/v1/logging",
    Header: '/v1/headers',
    TurnIn: '/v1/turnin'
};
export const AEM_CONTENT_URLS = {
    PROD: {
        BACKEND_URL: 'https://static.chase.com',
        IMAGES_BASE_URL_QA2: 'https://static.chasecdn.com',
    },
    NON_PROD: {
        BACKEND_URL: 'https://ccbcontent-qa2.chase.com',
        IMAGES_BASE_URL_QA2: 'https://static2-qa2.chasecdn.com',
    }
};
export const EXTERNAL_LINKS = {
    NATIONAL_DEALER_LOCATOR: 'https://www.jaguarusa.com/retailer-locator/index.html',
    FINANCE_CALCULATOR: 'https://www.landroverusa.com/current-offers/finance-calculator.html#/models',
    LAND_ROVER_USA: 'https://www.landroverusa.com/index.html',
    BUILD_YOUR_OWN: 'https://www.landroverusa.com/build-your-own/index.html',
    SEARCH_INVENTORY: 'https://inventory.landroverusa.com/new/#/',
    CERTIFIED_PRE_OWNED: 'https://www.landroverusa.com/certified-pre-owned/index.html',
    CALCULATOR_TRADE_IN: 'https://www.landroverusa.com/shopping-tools/calculate-trade-in-value.html',
    FEEDBACK: 'https://jpmc.surveymonkey.com/r/YC7JSHP',
    CURRENT_OFFERS: 'https://www.landroverusa.com/current-offers/index.html',
    RANGE_ROVER_FAMILY: 'https://www.landroverusa.com/range-rover/index.html',
    DISCOVER_FAMILY: 'https://www.landroverusa.com/discovery/index.html',
    FLEET_AND_BUSINESS: 'https://www.landroverusa.com/corporate-sales/fleet-and-business-sales.html',
    FACEBOOK: 'https://www.facebook.com/landroverUSA',
    TWITTER: 'https://www.x.com/landroverusa',
    YOUTUBE: 'https://www.youtube.com/user/landroverusa/',
    INSTAGRAM: 'https://www.instagram.com/landoverusa/',
    LINKEDIN: 'https://www.linkedin.com/company/jaguar-land-rover-north-america/',
    CHASE_PRIVACY: 'https://www.chase.com/digital/resources/privacy-security',
    CHASE_SECURITY: 'https://www.chase.com/digital/resources/privacy-security',
    CHASE_TERMS_AND_USE: 'https://www.chase.com/digital/resources/terms-of-use?jp_cdf=jfg',
    CHASE_ACCESSIBILITY: 'https://www.chase.com/digital/resources/accessibility?jp_cdf=jfg',
    AD_CHOICES: 'https://optout.aboutads.info/?c=2&lang=EN',
    CHASE_ENROLL: 'https://chase.com/enroll?jp_cdf=jfg',
    CHASE: 'https://www.chase.com/?jp_cdf=jfg',
    CHASE_AUTOPAY: 'https://www.chase.com/autopay?jp_cdf=jfg',
    CHASE_PAPERLESS: 'https://www.chase.com/paperless?jp_cdf=jfg',
    APP_STORE_URL: 'https://apps.apple.com/us/app/chase-mobile/id298867247',
    PLAY_STORE_URL: 'https://play.google.com/store/apps/details?id=com.chase.sig.android&amp;hl=en',
};
export const PDF = 'content-link';
export const FAQ_LINK = '/faq';
export const CONTACT_LINK = '/contact-us';
export const LEASE_COLLAPSE_TYPE = 'collapse';
const RETURN_LINKS = {
    ODOMETER_STATEMENT: 'https://www.chase.com/content/dam/chase-ux/documents/personal/auto-loans/odometer_statement_lease.pdf?jp_cdf=jfg',
    DIGITAL_LEASE_TURN_IN: '/lease-end-turnin',
    INSPECTION_LINK: 'https://autovinlive.com/Login/EntryGet',
    CONTACT_US: '/contact-us',
    SECURE_MESSAGE: 'https://www.chase.com/digital/login-secure-message?jp_cdf=jfg',
    LANDROVER_USA: 'https://www.landroverusa.com/index.html',
};
export const COLLAPSE_DATA = [
    {
        contentTitle: '60 days before you turn in your vehicle',
        testId: 'collapse-title-1',
        content: [
            {
                header: 'evaluate wear and use',
                testId: 'test-evaluate-wear-and-use',
                subContent: {
                    title: `As you get to the end of your lease, we want to help you make your turn-in as simple as possible. As detailed in your Lease Agreement, we may bill you for the following excess wear and use items:`,
                    items: [
                        {
                            text: `Holes, tears, burns, stains, strong odors or
                excessively worn areas in the carpet, other
                interior surfaces or convertible top. If yoars,
                burns, stains, strong odors or excessively worn
                areas in the carpet, other interior surfaces or
                convertible top.`,
                        },
                        {
                            text: `Scratches, chips, dents, pits, rust, holes or
                cracks in the wheels or exterior surfaces,
                windshield or other glass surfaces or metal work.`,
                        },
                        {
                            text: `Cracked, broken, or missing windows, doors,
                lights, trim, mirrors, or antennae.`,
                        },
                        {
                            text: `Missing, damaged or nonworking mechanical
                equipment, safety or emissions control equipment,
                electrical or other parts or accessories.`,
                        },
                        { text: `Damage to the vehicle's frame or alignment.` },
                        {
                            text: `Damage due to improper, poor quality or incomplete
                repairs or paint work. You also will remain liable
                to us for the full cost of any such items
                resulting from intentional or willful abuse or
                misuse.`,
                        },
                        {
                            text: `Any wear and use related to tires. Tire tread must
                be at least 1/8” (4/32”) at the lowest point and
                may not contain any other damages. Also make sure
                that all tires match and are comparable quality to
                the original equipment. Also make sure to remove
                snow tires prior to your pre-inspection. Other
                items may be excluded as set forth in the Lease
                Agreement.`,
                        },
                        {
                            text: `Any missing manuals or missing or nonworking keys
                or remote entry devices.`,
                        },
                    ],
                },
            },
            {
                header: 'schedule a pre-inspection',
                testId: 'test-schedule-a-pre-inspection',
                subContent: {
                    title: null,
                    items: [
                        {
                            text: `Schedule a <a href=${RETURN_LINKS.INSPECTION_LINK}>pre-inspection online</a> or <a href=${RETURN_LINKS.CONTACT_US}>contact us</a> to make the appointment. <sup>1</sup>`,
                        },
                        {
                            text: `Make your appointment at least 45 days before your
                turn-in date to ensure you can get one that fits
                your schedule.`,
                        },
                        {
                            text: `You can review your online condition report about 24
                hours after your appointment.`,
                        },
                        {
                            text: `If you don’t get a pre-inspection, we’ll still
                complete an inspection after you turn-in your
                vehicle, but you won’t have the ability to make
                repairs. Any excess wear and use charges will appear
                on your final bill.`,
                        },
                    ],
                },
            },
            {
                header: 'Notify us of any recent repairs',
                testId: 'test-notify-us-of-any-recent-repairs',
                subContent: {
                    title: null,
                    items: [
                        {
                            text: `If you make repairs to reduce your end-of-term
                liability,<sup>2</sup> let us know before you return your
                vehicle by sending a <a href=${RETURN_LINKS.SECURE_MESSAGE}>secure message</a> at chase.com.`,
                        },
                        {
                            text: `All repairs must be completed by a licensed repair
                facility. Please include receipts for all repairs.`,
                        },
                    ],
                },
            },
        ],
    },
    {
        contentTitle: '14 days before you turn in your vehicle',
        testId: 'collapse-title-2',
        content: [
            {
                header: 'Turn off automatic payments',
                testId: 'test-14-days-before-you-turn-in-your-vehicle',
                subContent: {
                    title: null,
                    items: [
                        {
                            text: `Make sure that you turn off any automatic payments once
            your final payment has cleared. We can’t cancel these
            payments for you, so if you don’t turn them off, you may
            be overcharged.`,
                        },
                    ],
                },
            },
            {
                header: 'Schedule the return of your vehicle',
                testId: 'test-schedule-the-return-of-your-vehicle',
                subContent: {
                    items: [
                        {
                            text: `Schedule an appointment to return your vehicle to your
            Land Rover retailer. If you need help finding a retailer,
            you can visit <a href=${RETURN_LINKS.LANDROVER_USA}>LandRoverUSA.com</a> to
            locate one.`,
                        },
                    ],
                },
            },
            {
                header: 'Find out local DMV requirements for turn-in',
                testId: 'test-find-out-local-DMV-requirements-for-turn-in',
                subContent: {
                    items: [
                        {
                            text: `Contact your local Department of Motor Vehicles to see if
            there are specific turn-in requirements for your area.
            Your retailer may assist during your turn-in appointment,
            but it’s your responsibility to complete the state
            requirements.`,
                        },
                    ],
                },
            },
        ],
    },
];
export const LEASE_RETURN_DATA = {
    dealerLocationLink: 'https://www.landroverusa.com/national-dealer-locator.html',
};
export const ALERT_TEXTS = {
    AlertHeading: " Your payment's past due",
    AlertText: ` Please make a payment as soon as you can so you don't fall
  behind on your account. If you've already made your payment,
  you're all set.`,
};
export const EXTEND_LEASE_DATA = {
    imageSectionTitle: 'Additional lease-end options',
};
export const EXTEND_LEASE_IMAGE_CARDS = [
    {
        dataTestId: 'extend-lease-image1',
        desktopWebUrl: 'https://jfg-uat.chase.com/_nuxt/card-new.lfWNg-dv.webp',
        desktopJpegUrl: 'https://jfg-uat.chase.com/_nuxt/card-new.dCG7QypL.jpg',
        title: 'EXPLORE a new land rover',
        content: 'Let Land Rover exceed your expectations. Learn what you need to know to purchase or lease a Land Rover.',
        linkText: 'SEE OPTIONS',
        linkTextHref: '/lease-end/new',
        isImageClickable: false,
    },
    {
        dataTestId: 'extend-lease-image2',
        desktopWebUrl: 'https://jfg-uat.chase.com/_nuxt/card-return.qgG-0-Gt.webp',
        desktopJpegUrl: 'https://jfg-uat.chase.com/_nuxt/card-return.AciQ2IvS.jpg',
        title: 'Return your current land rover',
        content: 'You’re getting close to the end of your lease, so it’s time to figure out how to return your Land Rover. Review our timeline so you can have a successful turn-in experience.',
        linkText: 'HOW TO RETURN YOUR LAND ROVER',
        linkTextHref: '/lease-end/return',
        isImageClickable: false,
    },
];
export const EXTEND_LEASE_CONTENT_INFO_TEXTS = [
    {
        id: 1,
        text: `1 If you decide to schedule a pre-inspection, it must be completed no more than 60 days prior to turn-in. If you turn in your vehicle more than 60 days after the completion of your pre-inspection, that inspection will expire. Please call us if you have an expired pre-inspection and we'll explain the next steps. If your lease originated in NH/WI: The pre-inspection must be completed within 15 days prior to turn-in. The holder's inspection after the termination of the lease shall be conclusive unless the NH/WI lessee obtains a counter-inspection.`,
        replacedText: '1',
        isSuperScriptAvailable: true,
        isHrefAvailable: false,
    },
    {
        id: 2,
        text: `2 You may have purchased an optional product(s) to cover costs associated with repairing the vehicle and to reduce or avoid end of term charges. If so, please carefully review the benefits and exclusions of any optional products you purchased. Contact your retailer to complete any covered repairs prior to turn-in.`,
        replacedText: '2',
        isSuperScriptAvailable: true,
        isHrefAvailable: false,
    },
    {
        id: 3,
        text: `3 Offer is valid for all current Land Rover Financial Group (jfg) lessees who: (a) Return the leased vehicle no earlier than 180 days prior to the originally contracted maturity date and no later than the originally contracted maturity date, or if extended, no later than the extended maturity date, and (b) Within 30 days of vehicle return, finance or lease a new Land Rover vehicle through jfg. Verification of current jfg lease may be required. This is an ongoing offer, but the program may be discontinued by jfg at any time without notice. Disposition fees are not charged on leases originated in Wisconsin. By contacting your Land Rover retailer about this offer, they will know that you are a valued Chase customer. Please contact your financial advisor for tax implications. New leases and financing through jfg are subject to credit approval. Additional terms and conditions apply.`,
        replacedText: '3',
        isSuperScriptAvailable: true,
        isHrefAvailable: false,
    },
];
export const TIMED_MODAL = 'Timed Modal';
export const UNTIMED_MODAL = 'Untimed Modal';
export const RETAILER_STORE = `YOUR LAND ROVER RETAILER`;
export const ACCOUNTS_CHASELINKS_ARRAY_1 = [
    {
        URL: 'https://jfg-uat.chase.com/_nuxt/icon-manage-autoplay.ke3yH-aX.svg',
        text: 'Manage automatic payments ',
        link: 'News',
    },
    { URL: computerSVG, text: 'Review pay-from accounts', link: 'News' },
    { URL: bookSVG, text: 'Manage paperless ', link: 'News' },
];
export const ACCOUNTS_CHASELINKS_ARRAY_2 = [
    {
        URL: leafSVG,
        text: 'Manage automatic payments ',
        link: 'News',
    },
    { URL: computerSVG, text: 'Send a secure message', link: 'News' },
    { URL: gearSVG, text: 'Manage paperless ', link: 'News' },
];
export const ACCOUNTS_DATA = {
    exploreMoreHeading: ' EXPLORE MORE WITH LAND ROVER',
    toolsHeading: 'YOUR AUTO TOOLS',
    manageHeading: 'MANAGE YOUR ACCOUNT AT CHASE.COM',
    elseHeading: 'LOOKING FOR SOMETHING ELSE? VISIT',
    chaseHeading: 'CHASE.COM',
    lastPaymentAmount: {
        heading: 'LAST PAYMENT AMOUNT',
        value: '$310.00',
    },
    lastPaymentDate: {
        heading: 'LAST PAYMENT DATE',
        value: 'JUN 2, 2021',
    },
    mailingAddress: {
        heading: 'ACCOUNT MAILING ADDRESS',
        row1: '1317 TILLAMACK ST',
        row2: 'LOAN MID-1 CYCLE',
        row3: ' BILLINGS, MT 59102-1022',
    },
    vinDetails: {
        heading: 'VIN',
        hiddenValue: '*********919584',
        actualValue: 'SALCP2FX3PH919584',
    },
    accDetails: {
        heading: 'Account Number',
        hiddenValue: '*****9325511',
        actualValue: '12117209325511',
    },
    loanTerm: {
        heading: 'Loan Term',
        popoverText: `This is your original loan term. If you've requested
    to defer any payments during your loan, your current
    loan term may be different than what is shown here.`,
    },
    organisationDate: {
        heading: 'Origination date',
        value: 'May 15, 2022',
    },
    accountDetailsHeading: 'land rover account details',
    accountProgress: {
        heading: 'COMPLETED PAYMENTS',
        value: '11 / 60',
        popOverText: `  This is your original loan term. If you’ve requested to
  defer any payments during your loan, your completed and
  total payments required to meet the terms of your agreement
  may be different than what is shown here.`,
    },
    remainingBalance: {
        heading: 'REMAINING BALANCE',
        value: '$14,414.71',
        popoverText: ` Your remaining balance is not the same as your payoff
  amount. Go to chase.com to get your payoff quote`,
    },
    intrestRate: { heading: 'INTEREST RATE', value: '2.74%' },
    mainCarImg: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/jaguar/MyAccount_NoImageAvailable_1.jpg',
    maiHeading: `CONGRATULATIONS ON YOUR LAND ROVER'S FIRST ANNIVERSARY!`,
    mainCarYear: '2023 Land Rover',
    mainCarName: 'Discovery Sport',
    paymentDetailsHeading: 'SEE PAYMENT DETAILS',
    paymentDetailsData: [
        {
            heading: 'Current payment due',
            value: '$301.25',
        },
        {
            heading: 'Amount past due',
            value: '$301.25',
        },
        {
            heading: 'Late charges',
            value: '$30.00',
        },
        {
            heading: 'Other fees',
            value: '$01.00',
        },
    ],
    paymentInfoFinalAmount: {
        heading: 'Total amount due',
        value: '$632.50',
    },
    paymentInfoMonthlyPayment: {
        heading: 'Monthly payment',
        value: '$301.25',
    },
    accountDetailsLink: 'Account Details',
    amountsDue: { heading: 'Amount due', value: '$632.50' },
    dueDate: { heading: 'Due Date', value: 'Nov 13, 2023' },
    editButton: 'EDIT',
};
export const CAR_COLOUR_SELECT = ['red', 'green', 'grey'];
export const PREAPPROVALOFFERPAGE = {
    preApprovalHead: 'YOUR PRE-APPROVAL OFFER',
    imageLinkDesktop: 'https://jfg-uat.chase.com/_nuxt/myoffers-hero-desktop.O0RY6gnF.webp',
    imageLinkMobile: 'https://jfg-uat.chase.com/_nuxt/myoffers-hero-mobile.A-M8tvlW.webp',
    imageLinkDesktopJPG: 'https://jfg-uat.chase.com/_nuxt/myoffers-hero-desktop.Aa4JfS0I.jpg',
    imageLink: 'https://jfg-uat.chase.com/_nuxt/myoffers-hero-mobile.HJeHDVGI.jpg',
    imageHeading: "You're pre-approved up to",
    amount: '$75,000',
    imagePara: 'toward the purchase or lease of a new Land Rover',
    imageSpan: 1,
    imageSpanHref: 'terms-and-conditions',
    paragraphHeading: 'To take advantage of your Land Rover Financial Group pre-approved  offer:',
    paragraphList: [
        {
            item: 'Choose a new Land Rover online or at a retailer.',
            span: '',
            href: '',
        },
        {
            item: 'Contact your retailer for a test drive and tell them your application is pre-approved by Land Rover Financial Group.',
            span: '2',
            href: 'valued-chase-customer-disclosure',
        },
    ],
    paragraphs: [
        { paragraph: ' Eligibility subject to change after March 27, 2024.' },
    ],
    subLinks: [
        {
            url: EXTERNAL_LINKS.NATIONAL_DEALER_LOCATOR,
            image: navigationSVG,
            urlName: ' Find a Jaguar retailer',
        },
        {
            url: 'faq',
            image: faqsSVG,
            urlName: 'FAQs',
        },
        {
            url: 'contact-us',
            image: peopleGroupSVG,
            urlName: 'Contact us',
        },
    ],
    footerHeader: 'Terms and Conditions',
    footers: [
        {
            item: `Information from a credit bureau was not used in connection with this
       preapproved offer. You were selected for this preapproval offer based on
       your current Land Rover Financial Group (jfg) account(s) being in good standing.
       When you apply through your retailer for jfg financing, jfg will honor these
       terms provided your financing meets jfg's guidelines, and you and your co-applicant,
       if applicable, also meet jfg's credit criteria. jfg will use a credit report to
       make a final decision on your application. You must be a U.S. resident.
       Used and certified pre owned vehicles excluded. Your new financing will
       be subject to minimum and maximum dollar amounts and payment periods.
       Eligibility subject to change. The offer period ends March 27, 2024,
       but may be renewed if preapproval criteria continue to be met. The
       dollar amount shown is the maximum amount that jfg will finance
       toward the lease or purchase.`,
            id: 'terms-and-conditions',
            stagNumber: '1',
        },
        {
            item: `By contacting your Land Rover retailer about this offer, they will
      know that you are a valued Chase customer and that you may meet other
      eligibility requirements. This offer may be discontinued at any time
      without notice.`,
            id: 'valued-chase-customer-disclosure',
            stagNumber: '2',
        },
        {
            item: `Lease customers: If you return your leased vehicle early, you may owe
      jfg an early termination liability, as due under your lease
      agreement. This may be a substantial charge depending on when you
      terminate during the lease term. Contact jfg to make sure you
      understand the financial details regarding early turn in.`,
        },
        {
            item: `Some offers and discounts may not be eligible to be combined. Ask your
      retailer about what offers and discounts may be combined.`,
        },
    ],
};
//AEM Configs
export const AEM_CONFIG = {
    ...getAEMBackendUrls(),
    VERSION: 'latest',
    FALLBACK_LAN: 'en',
};
export const CAROUSELTYPE = 'carousel';
export const AEM_MODELS = {
    LINKLIST: 'linkList',
    ICONLIST: 'iconList',
    CONTENTLIST: 'contentList',
    CAROUSEL: 'carousel',
    DIALOG: 'dialog',
    TILE: 'tile',
    STRUCTUREDCONTENTLIST: 'structuredContentList',
    LEGALCONTAINER: 'legalContainer',
    HEADER: 'header',
};
export const RETURN_OBJ = {
    returnObjects: true,
};
export const LINK_TYPES = {
    ANQUER: 'anquer',
    SUP: 'sup',
    BOLD: 'bold',
};
export const AEM_NAME_SPACES = {
    DEFAULT: 'auto/jaguar',
    COMMON: 'auto/common',
};
export const AEM_TAGS = {
    COMMON_TAG: 'auto/oem/jaguar/common',
    HOMEPAGE_TAG: 'auto/oem/jaguar/homepage',
    LOANPAGE_TAG: 'auto/oem/jaguar/loanend',
    LEASEPAGE_TAG: 'auto/oem/jaguar/leaseend',
    DIGITAL_LEASE_TURNIN_TAG: 'auto/oem/jaguar/digital-turn-in',
    FINANCING_AND_OFFERS_TAG: 'auto/oem/jaguar/financingandoffers',
    ERROR_PAGE_TAG: 'auto/oem/jaguar/errorpage',
    LEASE_EXPLORE_NEW_JAGUAR_TAG: 'auto/oem/jaguar/exploreanewjaguar',
    SITEMAPPAGE_TAG: 'auto/oem/jaguar/sitemap',
    EXTENDLEASEPAGE_TAG: 'auto/oem/jaguar/extendyourlease',
    CONTACTUS_TAG: 'auto/oem/jaguar/contact-us',
    FAQSPAGE_TAG: 'auto/oem/jaguar/faqs',
    NEWCUSTOMERPAGE_TAG: 'auto/oem/jaguar/newcustomer',
    PREAPPROVALPAGE_TAG: 'auto/oem/jaguar/preapprovalpage',
    ACCOUNTS_TAG: 'auto/oem/jaguar/myaccount',
    RETURNPAGE_TAG: 'auto/oem/jaguar/returnyourjaguar',
    COMMON_FOR_ALL_BRANDS_TAG: 'auto/oem/common',
};
export const AEM_IMAGES_PARAM = {
    SPLIT_WITH: '_Desktop1.jpg',
    JPG_EXT_PATH_DESKTOP: '_Desktop1.jpg',
    JPG_EXT_PATH_MOBILE: '_Mobile1.jpg',
    WEBP_EXT_PATH_DESKTOP: '_Desktop2.webp',
    WEBP_EXT_PATH_MOBILE: '_Mobile2.webp',
    CARD_SPLIT_PATH: '_1.jpg',
};
export const AEM_CONTENT_TRANSLATION_PARAM = {
    COMMON_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: { tag: AEM_TAGS.COMMON_TAG, version: 'latest' },
    },
    COMMON_FOR_ALL_BRANDS_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: { tag: AEM_TAGS.COMMON_FOR_ALL_BRANDS_TAG, version: 'latest' },
    },
    HOMEPAGE_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.HOMEPAGE_TAG,
            version: 'latest',
        },
    },
    LOANPAGE_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.LOANPAGE_TAG,
            version: 'latest',
        },
    },
    LEASEPAGE_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.LEASEPAGE_TAG,
            version: 'latest',
        },
    },
    FINANCING_AND_OFFERS_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.FINANCING_AND_OFFERS_TAG,
            version: 'latest',
        },
    },
    ERROR_PAGE_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.ERROR_PAGE_TAG,
            version: 'latest',
        },
    },
    LEASE_EXPLORE_NEW_JAGUAR_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.LEASE_EXPLORE_NEW_JAGUAR_TAG,
            version: 'latest',
        },
    },
    DIGITAL_LEASE_TURNIN_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.DIGITAL_LEASE_TURNIN_TAG,
            version: 'latest',
        },
    },
    SITEMAPPAGE_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.SITEMAPPAGE_TAG,
            version: 'latest',
        },
    },
    EXTENDLEASEPAGE_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.EXTENDLEASEPAGE_TAG,
            version: 'latest',
        },
    },
    CONTACTUS_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.CONTACTUS_TAG,
            version: 'latest',
        },
    },
    FAQSPAGE_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.FAQSPAGE_TAG,
            version: 'latest',
        },
    },
    NEWCUSTOMERPAGE_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.NEWCUSTOMERPAGE_TAG,
            version: 'latest',
        },
    },
    ACCOUNTS_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.ACCOUNTS_TAG,
            version: 'latest',
        },
    },
    PREAPPROVALPAGE_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.PREAPPROVALPAGE_TAG,
            version: 'latest',
        },
    },
    RETURNPAGE_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.RETURNPAGE_TAG,
            version: 'latest',
        },
    },
};
export const CONTACT_LINKS_ICON_LIST = [
    'navigationIcon',
    'faqIcon',
    'helpIcon',
];
export const card_concat_with_jpg = '_1.jpg';
export const card_concat_with_webp = '_2.webp';
export const NO_CONTENT_FOUND = 'No Content Found';
export const ARROW_WHITE_POINTING_RIGHT = `${AEM_CONFIG.IMAGES_BASE_URL_QA2}/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_white_pointing_right.svg`;
export const GREEN_ARROW_ICON = `${AEM_CONFIG.IMAGES_BASE_URL_QA2}/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_green_right.svg`;
export const OEM_BUTTON_TYPES = { PRIMARY: 'primary', SECONDARY: 'secondary' };
export const FAQ_SEARCH_RESULT_TEXT = {
    SHOW_WITH_COUNT: 'Showing counts result for "searchValue"',
    SHOW_WITHOUT_COUNT: 'No results found. Try a different search or',
};
export const ANALYTICS_CLASSNAME = {
    EXTERNAL_LINK: 'chaseanalytics-opt-exlnk',
    INTERNAL_LINK: 'chaseanalytics-track-link',
    INPUT_ELEMENT: 'chaseanalytics-track-element',
    SELECT_OPTION: 'chaseanalytics-track-option',
};
export const OEM_IMAGE_CATEGORY = {
    small: 'SM',
    large: 'LG',
    extraLarge: 'XL',
};
export const ERROR_PAGE_OBJ = {
    imageLink: {
        desktopWebpUrl: `${AEM_CONFIG.IMAGES_BASE_URL_QA2}/content/dam/unified-assets/photography/partner/land-rover/optimized/No_Active_Account_Hero_Desktop2.webp`,
        imageWebpUrl: `${AEM_CONFIG.IMAGES_BASE_URL_QA2}/content/dam/unified-assets/photography/partner/land-rover/optimized/No_Active_Account_Hero_Mobile2.webp`,
        desktopJpegUrl: `${AEM_CONFIG.IMAGES_BASE_URL_QA2}/content/dam/unified-assets/photography/partner/land-rover/optimized/No_Active_Account_Hero_Desktop1.jpg`,
        imageJpegUrl: `${AEM_CONFIG.IMAGES_BASE_URL_QA2}/content/dam/unified-assets/photography/partner/land-rover/optimized/No_Active_Account_Hero_Mobile1.jpg`
    },
    heading: "IT LOOKS LIKE THIS PART OF OUR SITE ISN'T WORKING RIGHT NOW",
    description: [
        {
            text: 'Please try again later.',
        },
        {
            text: 'Thanks for your patience.',
        },
    ],
};
export const PROTECTED_URLS = ['/myaccount', '/myoffers', '/lease-end-turnin', '/lease-end-turnin-confirmation', '/logout'];
export const QA_AUTH_URL_DOMAIN = 'https://qac3-secure01ea.chase.com/';
export const PROD_AUTH_URL_DOMAIN = 'https://secure.chase.com/';
export const NAV_KEYS = "web/auth/?navKey=oemNavJaguar&jp_cdf=JFG_LOGIN&context=OEM_JAGUAR&treatment=JAGUAR&financialBrandName=Jaguar+Financial+Group&landingScreen=";
export const PROD_LOGIN_URL = `${PROD_AUTH_URL_DOMAIN}${NAV_KEYS}`;
export const QA_LOGIN_URL = `${QA_AUTH_URL_DOMAIN}${NAV_KEYS}`;
export const LOGOUT_PATH = '#/dashboard/signout/partner/OEM_JAGUAR';
export const PROD_LOGOUT_URL = `${PROD_AUTH_URL_DOMAIN}${LOGOUT_PATH}`;
export const QA_LOGOUT_URL = `${QA_AUTH_URL_DOMAIN}${LOGOUT_PATH}`;
export const EXTEND_SESSION_PATH = "web/auth/user/session/extend";
export const PROD_EXTEND_SESSION_URL = `${PROD_AUTH_URL_DOMAIN}${EXTEND_SESSION_PATH}`;
export const QA_EXTEND_SESSION_URL = `${QA_AUTH_URL_DOMAIN}${EXTEND_SESSION_PATH}`;
export const AEM_LOGOUT_PATH = '/logout';
export const PAYMENT_PATH = 'web/auth/dashboard?navKey=requestOverviewDashboard&jp_cdf=JFG_MA_PMT';
export const AEM_PAYMENT_PATH = '/payment';
export const PAYMENT_PATH_LANDING_AFTER_AUTH = '/myaccount';
export const INACTIVE_TIMEOUT_DETAILS = {
    timeout: 1000 * 60 * 6, // 6 mins
    warningTimeout: 1000 * 60 // 1 min
};
export const PAGE_TITLES = {
    "/": 'Financing a Jaguar - Jaguar Financial Group',
    "/myaccount": 'Your Jaguar Financial Group Account',
    "/sitemap": 'Sitemap',
    "/lease-end": 'Your Jaguar Lease-End Options',
    "/lease-end/new": 'Get a New Jaguar',
    "/lease-end/other": 'Your Jaguar Lease-End Options',
    "/lease-end/return": 'Return your Jaguar',
    "/lease-end-turnin": 'Lease turn in form for your Jaguar',
    "/lease-end-turnin-confirmation": 'Thank You, your form was successfully submitted.',
    "/loan-end": 'Your Jaguar End of Loan Options',
    "/financing": 'Apply for financing or estimate payments on your next Jaguar',
    "/pay-manage": 'No Active Account',
    "/myoffers": 'You are pre-approved',
    "/welcome": 'Welcome to Jaguar Financial Group',
    "/error": 'Error page',
    "/oops": 'Oops, page not found',
    "/faq": 'FAQs - Jaguar Financial Group',
    "/contact-us": 'Contact us - Jaguar Financial Group'
};
export const ACCOUNT_STATUS = {
    ACTIVE: 'ACTIVE',
    EXTENDED: 'EXTENDED'
};
export const ACCOUNT_TYPE = {
    LOAN: 'LOAN',
    LEASE: 'LEASE'
};
export const DATE_ACTION = {
    ADD: 'ADD',
    SUBSTRACT: 'SUBSTRACT'
};
export const ALERT_TYPE = {
    ACTION: 'Action',
    INFO: 'Info',
    PROMO: 'Promo'
};
export const DOUBLE_OPEN_CURLY_BRACKETS = "{{";
export const DOUBLE_CLOSE_CURLY_BRACKETS = "}}";
